<template>
  <div class="box">
    <div>
      <img src="../../assets/images/wap/goback.png" alt="">
    </div>
    <span>直播大厅</span>
    <p>
      <img @click.stop="handleRoomList" src="../../assets/images/wap/check.png" alt="">
      <img @click.stop="sendOpenLive800" src="../../assets/images/wap/kefu.png" alt="">
    </p>
  </div>
</template>

<script>
import config from '../../config/index'
export default {
  name:'wapTab',
  computed:{
    roomInfo() {
      return this.$store.state.home.roomInfo
    },
  },
  methods:{
    // 房间切换按钮
    async handleRoomList() {
      await this.getRoomList()
      this.roomInfo.id ? this.$store.commit('home/set_roomtid', this.roomInfo.tId) : ''
      this.$store.commit('home/set_showroomlist', true)
    },
    // 获取房间列表
    async getRoomList() {
      const option = {
        platid: config.platid,
        page: 0,
        size: 10
      }
      await this.$store.dispatch('home/getRoomList', option)
    },
    // 点击客服，传递给父元素
    sendOpenLive800(){
      this.$emit('send');
    }
  }
}
</script>

<style lang="less" scoped>
  .box{
    width: 100%;
    height: 45px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 0 10px 0 20px;
    div{
      width: 90px;
      img{
        width: 8px;
      }
    }
    span{
      width: 90px;
      text-align: center;
      font-size: 15px;
    }
    p{
      img{
        width: 20px;
        margin: 0 8px;
      }
    }
  }
</style>