/* eslint-disable new-cap */
<template>
  <div class="classRoomBox">
    <div class="wapTab">
      <wapTab @send="live800"></wapTab>
    </div>
    <div class="home-page" @click.stop="handleGolbalEvent">
      <!-- head -->
      <!-- <app-head /> -->
      <!-- main -->
      <el-main class="home-page-main">
        <div class="video-section">
          <div class="main-header video-header">
          </div>
          <!-- 主播放器 -->
          <div class="video-main">
            <div v-show="ckplayerObject.live" id="ckplayerVideo" class="video-sec" />
            <video v-show="!ckplayerObject.live" id="ckplayerVideo" :src="ckplayerObject.video" autoplay="autoplay" class="video-sec" controls="true" />
            <!-- 暂时弃用 -->
            <!-- <video-player
              ref="videoPlayer"
              class="video-player vjs-custom-skin"
              :playsinline="true"
              :options="videoPlayerConfig"
              @play="onPlayerPlay($event)"
              @pause="onPlayerPause($event)"
              @ended="onPlayerEnded($event)"
            /> -->
          </div>
          <!-- craig -- swiper模块 -->
          <!-- <swiper-banner></swiper-banner> -->
          <!-- 房间列表 -->
          <!-- <transition name="fade"> -->
          <div v-show="showRoomList" class="room-list">
            <wapRoomlist></wapRoomlist>
          </div>
          <!-- </transition> -->
        </div>
        <!-- craig 引入讲师介绍组件 -->
        <div class="teacherIntroduce">
          <wapTeacher></wapTeacher>
        </div>
        <div class="chat-container">
          <div class="main-header chat-header">
            <!-- 用户列表按钮 -->
            <!-- <div class="chat-header-left" @click.stop="handleUserList">
              <img
                v-if="!showUserList"
                class="user-list-btn"
                src="./assets/images/chat_user_list.png"
                alt=""
              >
              <img
                v-if="showUserList"
                class="user-list-up-btn"
                src="./assets/images/up-icon.png"
                alt=""
              >
            </div> -->
            <!-- 房间标题 -->
            <div class="chat-header-right">
              <!-- <span>{{ roomInfo.name ? roomInfo.name : "聊天室" }}</span> -->
              <span :class="controlWindow ? 'currentSpan' : ''" @click="()=>{this.controlWindow = true}">
                互动窗口
              </span>
              <span :class="!controlWindow ? 'currentSpan' : ''" @click="()=>{this.controlWindow = false}">课堂笔记</span>
              <!-- <div class="chat-header-line" /> -->
            </div>
            <!-- 房间客服按钮 -->
            <!-- <div class="chat-header-living" @click.stop="handlePrivateChatEvent(1, {}, true)">
              <img src="./assets/images/living-icon.png" alt="客服私聊">
            </div> -->
          </div>
          <!-- 聊天区域 -->
          <div class="chat-main">
            <transition name="fade">
              <div v-if="showUserList" class="user-list">
                <!-- 用户列表组件 -->
                <user-list @closeUser="handleUserList" />
              </div>
            </transition>
            <!-- 聊天区域 -->
            <div class="chat-section">
              <!-- 聊天内容展示区域 -->
              <div v-if="controlWindow">
                <chat-list @handleEvent="handleEvent" />
              </div>
              <!-- craig -- 课堂讲义模块 -->
              <div v-else>
                <handouts></handouts>
              </div>
            </div>
          </div>
        </div>
      </el-main>
      <el-dialog
        :visible.sync="dialogObj.dialogVisible"
        :width="dialogObj.width"
        :show-close="false"
        :close-on-click-modal="false"
      >
        <!-- 关闭按钮 -->
        <div
          v-if="dialogObj.isCloseIcon"
          class="dialog-close"
          @click.stop="handleCloseDialog"
        />
        <!-- 登录弹出组件 -->
        <login-item
          v-if="dialogObj.type === 1"
          @loginSuccess="handleLoginSuccess"
        />
        <!-- 禁言弹出组件 -->
        <stop-word v-if="dialogObj.type === 2" />
        <!-- 私聊弹出组件 -->
        <private-talk v-if="dialogObj.type === 3" />
      </el-dialog>
      <el-dialog title="" :visible.sync="craigDialog.registerVisible" :width="craigDialog.registerWidth">
        <div class="registerBox">
          <img class="registerSuccess" src="./assets/images/craig/registerSuccess.png" alt="">
          <div class="registerCenter">
            <div class="registerCenterLeft">
              <p>
                <img src="./assets/images/craig/code.png" alt="">
                <span>扫码下载APP</span>
              </p>
              <button>IOS版</button>
              <button>Android版</button>
            </div>
            <div class="registerCenterRight">
              已下为您的账户密码：
              <p>
                <span>登录账号：{{userInfo.username}}</span>
                <span>登录密码：{{userInfo.decodepassword}}</span>
              </p>
              尊敬的用户为了方便您的交易，红狮为您生成了MT4交易账号<br>
              您可以使用MT4交易账号交易，也可以直接登录直播间，以上信息将会发送至您的手机<br>
              我们会严格保护您的数据安全
            </div>
          </div>
          <div class="registerButton">
            <p @click="closerDialog">返回直播间</p>
            <p>充多少送多少</p>
          </div>
        </div>
      </el-dialog>
      <el-dialog
        :visible.sync="dialogObj3.dialogVisible"
        :width="dialogObj3.width"
        :show-close="false"
        class="loginBox"
      >
        <!-- 关闭按钮 -->
        <div
          v-if="dialogObj3.isCloseIcon"
          class="dialog-close"
          @click.stop="handleCloseDialog3"
        />
        <!-- 忘记密码弹出组件 -->
        <forget-item
          v-if="dialogObj3.type === 1"
          @loginSuccess="handleLoginSuccess"
        />
      </el-dialog>
      <el-dialog
        :visible.sync="dialogObj4.dialogVisible"
        :width="dialogObj4.width"
        :show-close="false"
        :close-on-click-modal="false"
      >
        <div
          v-if="dialogObj4.isCloseIcon"
          class="dialog-close"
          @click.stop="handleCloseDialog4"
        />
        <div class="dialog-craigNew">
          <p class="craigNewP">红狮课堂</p>
          <div>
            <h3 v-if="dialogObj4.gq">感谢关注，您的观看时间剩余<span>{{ this.tipTime.minute }}</span>分钟!</h3>
            <h3 v-if="!dialogObj4.gq">感谢关注，游客观看时间已结束!</h3>
            <p>马上注册或者登录账号，观看海量精彩直播</p>
            <button @click="handleLogin(1)">登录</button>
            <button @click="handleLogin(2)">
              <img src="./assets/images/craig/500.png" alt="">
              注册立领$500现金券
            </button>
          </div>
        </div>
      </el-dialog>
    </div>
    <div class="wapBottom">
      <!-- 功能按钮区域（禁止聊天自动滚动） -->
      <div class="chat-handler">
        <div class="headerBox" v-if="userInfo.id && userInfo.nick.indexOf('游客') == -1">
          <img @click="()=>{control.showDiv = ! control.showDiv}" class="showHeaderImg" :src="control.headerImg ? control.headerImg : control.imgSrc[0]" alt="">
          <div class="positionBox" v-if="control.showDiv">
            <img @click="()=>{control.showDiv = ! control.showDiv}" class="closer" src="./assets/images/craig/closer.png" alt="">
            <p>
              <img v-for="(item,i) in control.imgSrc" :key="i" :class="control.target == i ? 'borderImg' : ''" @click="tag(i,item)" :src="item" alt="">
            </p>
            <span @click="changeImg">更换头像</span>
          </div>
        </div>
        <!-- wap端没有登录时 -->
        <div v-else class="noLogin">
          <img @click="handleLogin(3)" src="./assets/images/craig/prime.png" alt="">
        </div>
        <div class="scroll-control">
          <!-- <img v-if="!isatuoscroll" src="./assets/images/start-scroll-icon.png" alt="" @click.stop="handleAutoScroll(true)">
          <img v-if="isatuoscroll" src="./assets/images/stop-scroll-icon.png" alt="" @click.stop="handleAutoScroll(false)"> -->
          <!-- craig -- 新增 -->
          <img @click.stop="()=>{controlShowChat = !controlShowChat}" src="./assets/images/teacherJs/yanjing.png" alt="">
          <!-- <p>
            <img @click.stop="live800" src="./assets/images/craig/live.png" alt="">
            <span v-if="showRed"></span>
          </p> -->
          <ul v-if="controlShowChat">
            <li :class="controlClass == 1 ? 'liRed' : ''" @click.stop="selectShow(1)">全部</li>
            <li :class="controlClass == 2 ? 'liRed' : ''" @click.stop="selectShow(2)">只看我</li>
            <li :class="controlClass == 3 ? 'liRed' : ''" @click.stop="selectShow(3)">只看讲师</li>
          </ul>
        </div>
        <!-- 发送区域 -->
        <div class="send-section">
          <!-- 输入区域 -->
          <div class="send-input">
            <textarea ref="inputSection" v-model="sendTxt" @keyup.enter="handleSendEvent" :disabled="!controlWindow"/>
            <p v-if="craig" class="textareaTip"><span @click="handleLogin(3)" style="color:#e90014">登录/注册 </span> 即可与讲师畅所欲言</p>
          </div>
          <!-- 发送按钮 -->
          <div class="send-btn">
            <button @click.stop="handleSendEvent">发送</button>
          </div>
        </div>
      </div>
      <!-- 回复引用展示区域 -->
      <div v-if="replyCall.show" class="reply-section">
        <span>{{ replyCall.name }}</span>
        <p>{{ replyCall.txt }}</p>
        <span class="class-reply-btn" @click="handleCloseReplyEvent" />
      </div>
    </div>
  </div>
</template>

<script>
import AppHead from './components/appHead'
import RoomList from './components/roomList'
import UserList from './components/userList'
import ChatList from './components/chatList'
import LoginItem from './components/login'
import StopWord from './components/stopwords'
import PrivateTalk from './components/privatetalk'
import wapTeacher from './components/wap/wapTeacher.vue'
import config from './config/index'
import SwiperBanner from './components/swiperBanner.vue'
import Handouts from './components/handouts.vue'
import ForgetItem from './components/forget'
import wapTab from './components/wap/wapTab.vue'
import wapRoomlist from './components/wap/wapRoomlist.vue'
export default {
  name: 'classRoomWap',
  components: {
    AppHead,
    RoomList,
    UserList,
    ChatList,
    LoginItem,
    StopWord,
    PrivateTalk,
    wapTeacher,
    SwiperBanner,
    Handouts,
    ForgetItem,
    wapTab,
    wapRoomlist,
  },
  data() {
    return {
      sendTxt: '', // 发送的内容
      sendType: 0, // 发送的消息类型:0、发送普通消息 1、发送回复引用消息 (2、禁言 3、私聊 4、撤回发言) 5、发送@别人的消息
      replyCall: {
        show: false,
        name: '',
        txt: ''
      },
      sendData: {
        broomid: '', // 房间id
        sname: '', // 消息发送人昵称
        sid: '', // 消息发送人id
        rid: '', // 消息接收人id(@别人使用，其他情况为空)
        rname: '', // 消息接收人昵称(@别人使用，其他情况为空)
        type: 0, // 消息类型(11、私聊（必传）)
        param: {
          TXT: '', // 消息内容
          CITE_TXT: '', // 回复引用的内容（回复引用功能下使用）
          CITE_TXT_ID: '', // 回复引用消息id（回复引用功能下使用）
          CITE_TXT_NAME: '' // 回复引用的昵称（回复引用功能下使用）
        }
      },
      craigDialog:{
        registerVisible: false,
        registerWidth: '500px'
      },
      // 控制切换互动窗口和课堂讲义
      controlWindow: true,
      // 控制筛选聊天盒子是否显示
      controlShowChat: false,
      controlClass:'',
      screenWidth: null,
      // isLogin:false,
      imgSrc:require('./assets/images/craig/headerImg.png'),
      timer:null,
      liveBody:false,
      // 控制头像相关的data
      control:{
        imgSrc:[
          require('./assets/images/craig/avatar/1.png'),
          require('./assets/images/craig/avatar/1.1.png'),
          require('./assets/images/craig/avatar/2.png'),
          require('./assets/images/craig/avatar/2.1.png'),
          require('./assets/images/craig/avatar/3.png'),
          require('./assets/images/craig/avatar/3.1.png'),
          require('./assets/images/craig/avatar/4.png'),
          require('./assets/images/craig/avatar/4.1.png'),
          require('./assets/images/craig/avatar/5.png'),
          require('./assets/images/craig/avatar/5.1.png'),
          require('./assets/images/craig/avatar/6.png'),
          require('./assets/images/craig/avatar/6.1.png'),
          require('./assets/images/craig/avatar/7.png'),
          require('./assets/images/craig/avatar/7.1.png'),
          require('./assets/images/craig/avatar/8.png'),
          require('./assets/images/craig/avatar/8.1.png'),
        ],
        showDiv:false,
        target:'',
        realImg:'',
        headerImg:null,
      },
    }
  },
  computed: {
    showRoomList() {
      return this.$store.state.home.showRoomList
    },
    ckplayerObject() {
      return this.$store.state.home.ckplayerObject
    },
    player() {
      return this.$store.state.home.player
    },
    showUserList() {
      return this.$store.state.home.showUserList
    },
    dialogObj() {
      return this.$store.state.home.dialogObj
    },
    dialogObj3() {
      return this.$store.state.home.dialogObj3
    },
    dialogObj4() {
      return this.$store.state.home.dialogObj4
    },
    userInfo() {
      return this.$store.state.login.userInfo
    },
    roomList() {
      return this.$store.state.home.roomList
    },
    roomInfo() {
      return this.$store.state.home.roomInfo
    },
    socket() {
      return this.$store.state.home.socket
    },
    privateUserList() {
      return this.$store.state.home.privateUserList
    },
    videoPlayerConfig() {
      return this.$store.state.home.videoPlayerConfig
    },
    roomset() {
      return this.$store.state.home.roomset
    },
    roomtid() {
      return this.$store.state.home.roomtid
    },
    isatuoscroll() {
      return this.$store.state.home.isatuoscroll
    },
    registerSuccess(){
      return this.$store.state.login.registerSuccess
    },
    // craig--newAdd
    teacherInfo(){
      return this.$store.state.home.teacherInfo
    },
    tipTime(){
      return this.$store.state.home.tipTime
    },
    isguestmessage(){
      return this.$store.state.home.isguestmessage
    },
    userPwd(){
      return this.$store.state.home.userPwd
    },
    isLogin(){
      return this.$store.state.login.isLogin
    },
    chartShowControl(){
      return this.$store.state.login.chartShowControl
    },
    controlTimer(){
      return this.$store.state.home.controlTimer
    },
    distributecustomer(){
      return this.$store.state.home.distributecustomer
    },
    showRed(){
      return this.$store.state.home.showRed
    },
    craig(){
      if(this.userInfo.id && this.userInfo.nick.indexOf('游客') == -1){
        clearInterval(this.timer)
        if(this.dialogObj4.dialogVisible){
          this.$store.commit('home/set_dialogobj4', { dialogVisible: false, isCloseIcon: true,width: '98%', });
        }
        return false
      }else{
        if(this.isguestmessage == 0){
          return true
        }else{
          return false;
        }
      }
    }
  },
  created() {
    this.pageInit();
    // craig-- 获取老师和课程信息
    this.getTeacherInfo();
    setTimeout(()=>{
      // craig--获取课堂笔记列表
      this.getBnotes();
    },800)
    // craig--获取登录提示时间间隔
    this.loginSetTime();
    // 传给App.vue,不展示tabbar
    this.$emit('controlShow',true);
    // 通过token判断是否已登录,通过nick判断是否为游客，通过utype判断是否禁止发言
    if(this.userInfo.utype == 1){
      this.$store.commit('login/set_isLogin',true);
    }else{
      if(this.userInfo.nick.indexOf('游客') !== -1){
        this.$store.commit('login/set_isLogin',false);
      }else if(JSON.parse(sessionStorage.getItem('token')).token){
        this.$store.commit('login/set_isLogin',true);
      }
    }
  },
  watch:{
    'registerSuccess'(newVal){
      if(newVal){
        this.craigDialog.registerVisible = true;
      }
    },
    'tipTime'(newVal){
      if(newVal){
        // craig--隔固定时间打开提示弹框
        if(!this.isLogin){
          this.craigSetTime();
        };
      }
    },
    'controlTimer'(newVal){
      if(newVal){
        clearInterval(this.timer)
      }
    },
    // 监听路由变化
    $route(to){
      console.log(to);
    },
    screenWidth: function (n) {
      if (n > 500) {
        this.$router.push({
          name:'classRoom',
        })
      }
    }
  },
  mounted(){
    this.$store.commit('home/set_craigPlay',true);
    //获取屏幕尺寸
    this.screenWidth = document.body.clientWidth
    window.onresize = () => {
      //屏幕尺寸变化
      return (() => {
          this.screenWidth = document.body.clientWidth
      })()
    },
    // 阻止chartlist输入框的键盘事件冒泡
    this.$nextTick(() => {
      let that = this
      let myTextarea = that.$refs['inputSection']	//chartlist输入区
      //监听键盘方向左右键
      myTextarea.addEventListener("keydown", (event) => {
        if (event.keyCode === 37) {
          event.stopPropagation();
        }else if(event.keyCode === 39){
          event.stopPropagation();
        }
      })
    })
  },
  destroyed(){
    sessionStorage.setItem('destroyed',true);
  },
  methods: {
    // 初始化页面
    async pageInit() {
      // 获取禁言列表参数数据
      await this.$store.dispatch('home/getDicts', { name: 'sys_forbid_time' })
      await this.$store.dispatch('home/getDicts', { name: 'sys_forbid_reason' })
      // 进入页面获取房间列表
      await this.getRoomList()
      // 判断房间信息和用户信息是否已经存在，存在就直接更改当前房间的视频地址
      if (this.userInfo.id && this.roomInfo.id) {
        // 获取选中房间的视频流地址或者默认视频地址 设置视频流地址或者默认播放视频 isplay: true 直播中（设置视频流地址）  false 不在直播（设置默认视频地址）
        // 获取当前房间的信息
        await this.getRoomInfo(this.roomInfo)
        this.$store.commit('home/set_isplay', this.roomInfo.isplay)
        if (this.roomInfo.isplay) {
          this.$store.commit('home/set_ckplayerObject', { video: this.roomInfo.param.pcurl, poster: '', live: true })
          // eslint-disable-next-line no-undef
          this.$store.commit('home/set_player', new Ckplayer(this.ckplayerObject))
          // vue-video-player插件，暂时废弃该方法
          // this.$store.commit('home/set_videoPlayerConfig', { sources: [{ src: this.roomInfo.param.pcurl, type: 'application/x-mpegURL' }], poster: '' })
        } else {
          // 获取默认视频信息(测试环境发现有些房间的默认播放视频的id为空，所以加了判断)
          this.roomInfo.param.efile_id ? await this.$store.dispatch('home/getRoomDefaultVideoInfo', { id: this.roomInfo.param.efile_id, type: 0 }) : ''
        }
        // 连接socket
        const params = {
          roomid: this.roomInfo.id,
          uid: this.userInfo.id
        }
        this.$store.dispatch('home/socketInit', params)
        // 获取房间在线用户列表
        await this.$store.dispatch('home/getCurrentRoomUser', {
          broomid: this.roomInfo.id
        })
        // this.roomInfo.id ? this.onPlayerPlay(this.$refs['videoPlayer'].player) : ''
      }
    },
    // 登录成功后处理事件(登录成功后循环获取房间信息)
    handleLoginSuccess() {
      const list = Object.assign([], this.roomList)
      list.length &&
        list.forEach(async item => {
          await this.getRoomInfo(item)
        })
      this.$store.commit('home/set_roomList', list)
      this.pageInit();
    },
    // 获取房间列表
    async getRoomList() {
      const option = {
        platid: config.platid,
        page: 0,
        size: 10
      }
      await this.$store.dispatch('home/getRoomList', option)
    },
    // craig--获取老师和课程信息
    async getTeacherInfo(){
      await this.$store.dispatch('home/getTeacherInfo', {broomid:this.roomInfo.id})
    },
    // craig--获取课堂笔记列表
    async getBnotes(){
      const option = {
        // broomid:this.roomInfo.id,
        broomid:this.roomInfo.id,
        tid:this.teacherInfo && this.teacherInfo[1].id,
        bsheetid:this.teacherInfo && this.teacherInfo[0].value.id,
        // bsheetid:42,
        sort:'ut,DESC',
      }
      await this.$store.dispatch('home/getBnotes',option)
    },
    // craig--获取登录提示时间间隔
    async loginSetTime(){
      await this.$store.dispatch('home/loginSetTime',{platid:config.platid})
    },
    // craig--根据登录提示时间间隔和次数控制弹框
    craigSetTime(){
      if(this.tipTime.minute){
        let minute = Number(this.tipTime.minute);
        console.log('============================',this.tipTime.minute);
        var timer = setTimeout(()=>{
          console.log('+++++++++++++++',this.tipTime.num);
          if(Number(this.tipTime.num) > 0){
            this.$store.commit('home/set_dialogobj4', {
              dialogVisible: true,
              isCloseIcon: true,
              width: '98%',
              gq:true,
            })
          }else if(Number(this.tipTime.num) == 0){
            console.log('-----------------');
            this.$store.commit('home/set_dialogobj4', {
              dialogVisible: true,
              isCloseIcon: false,
              width: '98%',
              gq:false,
            })
            clearInterval(timer);
          }
        },minute*60*1000);
      }
    },
    // craig--判断游客是否可以发言
    async isguestmessageMethod(){
      await this.$store.dispatch('home/isguestmessage',{platid:config.platid})
    },
    // 获取房间信息
    async getRoomInfo(item) {
      const option = {
        userId: this.userInfo.id || '',
        'broom.id': item.id,
        'bclass.type': 1
      }
      await this.$store.dispatch('home/getRoomInfo', option).then(res => {
        if (res.Status === 0) {
          res.liveStatus === '1' ? item.isplay = true : item.isplay = false // true直播中 false非直播中
          item.regStatus = res.regStatus || '' // 1可以报名 2 报名过期 3 已报名
          item.tId = res.tId || '' // 当前直播中老师的id
          item.classId = res.classId || '' // 可报名课程的id
          item.onLineNum = res.onLineNum || 0 // 在线人数
          item.regNum = res.regNum || '' // 报名人数
        }
      })
    },
    // 发言更多操作
    handleEvent(type, item) {
      // type:1、回复引用 2、禁言 3、私聊 4、撤回发言 5、@别人
      switch (type) {
        case 1:
          this.sendType = 1
          this.$refs['inputSection'].focus()
          this.handleReplyEvent(item)
          break
        case 2:
          this.handleStopEvent(item)
          break
        case 3:
          this.handlePrivateChatEvent(2, item, false,true)
          break
        case 4:
          this.handleRecallEvent(item)
          break
        case 5:
          this.sendType = 5
          this.$refs['inputSection'].focus()
          this.handleOtherPeopleEvent(item)
          break
        default:
          this.sendType = 0
          break
      }
    },
    // 回复引用
    handleReplyEvent(item) {
      this.replyCall = {
        show: true,
        name: item.sname,
        txt: item.param.TXT
      }

      const nick = String(this.userInfo.mtaccr).replace(String(this.userInfo.mtaccr).substring(2, String(this.userInfo.mtaccr).length - 2), '****')
      this.sendData = {
        broomid: this.roomInfo.id,
        sname: this.userInfo.nick || nick,
        sid: this.userInfo.id,
        rid: '',
        rname: '',
        type: 0,
        param: {
          TXT: this.sendTxt,
          CITE_TXT: item.param.TXT,
          CITE_TXT_ID: item.id,
          CITE_TXT_NAME: item.sname
        }
      }
    },
    // 关闭回复引用
    handleCloseReplyEvent() {
      this.replyCall = {
        show: false,
        name: '',
        txt: ''
      }
      const nick = String(this.userInfo.mtaccr).replace(String(this.userInfo.mtaccr).substring(2, String(this.userInfo.mtaccr).length - 2), '****')
      this.sendData = {
        broomid: this.roomInfo.id,
        sname: this.userInfo.nick || nick,
        sid: this.userInfo.id,
        rid: '',
        rname: '',
        type: 0,
        param: {
          TXT: '',
          CITE_TXT: '',
          CITE_TXT_ID: '',
          CITE_TXT_NAME: ''
        }
      }
    },
    // 禁言
    handleStopEvent(item) {
      this.$store.commit('home/set_stopWordsUserInfo', item)
      this.$store.commit('home/set_dialogobj', {
        dialogVisible: true,
        isCloseIcon: true,
        type: 2,
        width: '500px'
      })
    },
    // 撤回发言
    handleRecallEvent(item) {
      const option = {
        broomid: this.roomInfo.id,
        type: '12',
        id: item.id,
        sid: item.sid
      }
      this.socket.send(JSON.stringify(option))
    },
    // @别人
    handleOtherPeopleEvent(item) {
      this.sendTxt = '@' + item.sname
      const nick = String(this.userInfo.mtaccr).replace(String(this.userInfo.mtaccr).substring(2, String(this.userInfo.mtaccr).length - 2), '****')
      this.sendData = {
        broomid: this.roomInfo.id,
        sname: this.userInfo.nick || nick,
        sid: this.userInfo.id,
        rid: item.sid,
        rname: item.sname,
        type: 0,
        param: {
          TXT: this.sendTxt,
          CITE_TXT: '',
          CITE_TXT_ID: '',
          CITE_TXT_NAME: ''
        }
      }
    },
    onPlayerPlay(player) {
      const myPlayer = this.$refs.videoPlayer.player
      myPlayer.play()
      this.$message({
        type: 'warning',
        message: '请您自行打开视频声音'
      })
    },
    onPlayerPause(event) {},
    onPlayerEnded(event) {},
    // 房间切换按钮
    async handleRoomList() {
      await this.getRoomList()
      this.roomInfo.id ? this.$store.commit('home/set_roomtid', this.roomInfo.tId) : ''
      this.$store.commit('home/set_showroomlist', true)
    },
    // 用户列表切换按钮
    async handleUserList() {
      if (!this.userInfo.id) {
        this.$message({
          type: 'error',
          message: '请您登录，并选择房间后再查看用户列表'
        })
        return false
      } else {
        if (!this.roomInfo.id) {
          this.$message({
            type: 'error',
            message: '请您选择房间后再查看用户列表'
          })
          return false
        }
      }
      // 获取当前房间的在线用户
      await this.$store.dispatch('home/getCurrentRoomUser', {
        broomid: this.roomInfo.id
      })
      this.$store.commit('home/set_showuserlist', !this.showUserList)
      if (!this.showUserList) {
        this.$store.commit('home/set_currentroomuserlist', [])
      }
    },
    /**
     * 私聊弹出窗触发点（1、客服点击页面私聊按钮 2、客服在聊天记录中点击私聊 3、socket接收到私聊信息 4、用户列表触发私聊）
     * 此处只处理情况1和2(情况4与情况2的处理逻辑想通，只是触发是在用户列表中)，情况3放到socket中处理
     * 情况1：先请求当前客服的私聊用户列表 1-1：私聊用户列表为空，不打开私聊窗口，提示客服到聊天记录中选择一个用户去私聊；1-2私聊用户列表不为空，默认当前私聊用户信息为列表第一个用户,并请求和第一个用户的聊天记录。打开私聊窗口
     * 情况2：先请求当前客服私聊用户列表 2-1：私聊用户列表为空，将当前选中的私聊用户添加到列表中，并同时修改当前私聊用户信息为该选中用户；2-2私聊用户列表不为空，将当前用户添加到列表第一个，并修改当前默认私聊用户信息为该选中用户以及修改状态机中私聊用户列表，请求和该用户的私聊聊天记录。以上两种都需要最后打开私聊窗口（注意这种情况下需要去重处理）
     * type: 1、客服点击页面私聊按钮 2、用户在聊天记录中点击用户私聊
     * info: 选中用户的信息{ id: '', nick: ''}
     * **/
     async handlePrivateChatEvent(type, info, bool ,isFalse) {
      console.log(type, info, bool, isFalse);
      if(!isFalse){
        // 普通用户业务逻辑
        const option = {
          broomid:this.roomInfo.id,
          id:this.userInfo.id,
          platid:1,
        }
        // 获取分配的客服
        await this.$store.dispatch('home/getDistributecustomer', option)
        // 请求和选中私聊用户的聊天记录
        if(this.distributecustomer.id){
          const params = {
            broomid: this.roomInfo.id,
            type: 11,
            id1: this.userInfo.id,
            id2: this.distributecustomer.id,
            page: 0,
            size: 50,
          }
          await this.$store.dispatch('home/getUserPrivateChatHistoryListById', params)
          // 打开私聊窗口
          this.$store.commit('home/set_dialogobj', {
            dialogVisible: true,
            isCloseIcon: false,
            type: 3,
            width: '98%'
          })
        }
      }else{
        // 客服和讲师的业务逻辑
        this.$store.commit('home/set_ishomePrivateBtn', bool)
        if (!this.userInfo.id) {
          this.$message({
            type: 'error',
            message: '请您登录，并选择房间后再查看私聊信息'
          })
          return false
        }
        // 请求当前客服或者老师的私聊用户列表
        const option = {
          id: this.userInfo.id, // 当前登录老师或者客服id
          broomid: this.roomInfo.id // 当前所在房间id
        }
        await this.$store.dispatch('home/getUserListByManagerId', option)
        const list = Object.assign([], this.privateUserList)
        console.log(list.length);
        // 根据不同情况进行判断并处理对应逻辑
        if (type === 1) {
          if (list.length) {
            // 设置状态机当前私聊用户信息
            this.$store.commit('home/set_privateUserInfo', list[0])
            // 请求和选中私聊用户的聊天记录
            const params = {
              broomid: this.roomInfo.id,
              type: 11,
              id1: this.userInfo.id,
              id2: list[0].id,
              page: 0,
              size: 50
            }
            await this.$store.dispatch('home/getUserPrivateChatHistoryListById', params)
            // 打开私聊窗口
            this.$store.commit('home/set_dialogobj', {
              dialogVisible: true,
              isCloseIcon: false,
              type: 3,
              width: '98%'
            })
          }
        } else {
          if (list.length) {
            // 用户列表不为空，要去重，先检查列表中有没有该用户，如果有就先移除，然后把当前用户添加进去；如果没有直接添加进去
            const regList = []
            list.forEach(item => {
              regList.push(item.id)
            })
            if (regList.indexOf(info.sid) > -1) {
              list.splice(regList.indexOf(info.sid), 1)
            }
            list.unshift({ id: info.sid, nick: info.sname })
          } else {
            list.push({ id: info.sid, nick: info.sname })
          }
          // 设置私聊用户列表
          this.$store.commit('home/set_privateUserList', list)
          // 设置状态机当前私聊用户信息
          this.$store.commit('home/set_privateUserInfo', { id: info.sid, nick: info.sname })
          // 请求和选中私聊用户的聊天记录
          const params = {
            broomid: this.roomInfo.id,
            type: 11,
            id1: this.userInfo.id,
            id2: info.sid,
            page: 0,
            size: 50
          }
          await this.$store.dispatch('home/getUserPrivateChatHistoryListById', params)
          // 打开私聊窗口
          this.$store.commit('home/set_dialogobj', {
            dialogVisible: true,
            isCloseIcon: false,
            type: 3,
            width: '98%'
          })
        }
      }
      
    },
    // 发送事件处理(点击发送按钮)
    handleSendEvent() {
      if(this.userInfo.id == ''){
        this.$message({
          type: 'error',
          message: '请先登录后发言'
        })
        return false
      }
      if (!this.userInfo.id && !this.roomInfo.id) {
        this.$message({
          type: 'error',
          message: '请先登录并进入房间'
        })
        return false
      }
      const TXT = this.sendTxt.replace(/\s*/g, '')

      if (TXT === '') {
        this.$message({
          showClose: true,
          message: '发送内容不能为空',
          type: 'error'
        })
        return false
      } else if (TXT.length > this.roomset.content_lenth) {
        this.$message({
          showClose: true,
          type: 'error',
          message: `输入内容长度不能超过${this.roomset.content_lenth}`
        })
        return false
      }
      let option = {}
      if (this.sendType === 0) {
        const nick = String(this.userInfo.mtaccr).replace(String(this.userInfo.mtaccr).substring(2, String(this.userInfo.mtaccr).length - 2), '****')
        var headerimg = null;
        //utype --> 0:普通用户 1:讲师
        if(this.userInfo.utype == 0 || this.userInfo.utype == undefined){
          if(sessionStorage.getItem('headerImg')){
            headerimg = sessionStorage.getItem('headerImg');
          }else{
            headerimg = this.imgSrc;
          }
        }else{
          headerimg = this.userInfo.avatar;
        }
        option = {
          broomid: this.roomInfo.id,
          sname: this.userInfo.whoami || this.userInfo.nick || nick,
          sid: this.userInfo.id,
          type: 0,
          platid:config.platid,
          param: {
            TXT: this.sendTxt.replace('@' + this.sendData.rname, '')
          },
          headerimg,
        }
        option = Object.assign({}, option)
      } else {
        const nick = String(this.userInfo.mtaccr).replace(String(this.userInfo.mtaccr).substring(2, String(this.userInfo.mtaccr).length - 2), '****')
        var headerimg = null;
        //utype --> 0:普通用户 1:讲师
        if(this.userInfo.utype == 0 || this.userInfo.utype == undefined){
          if(sessionStorage.getItem('headerImg')){
            headerimg = sessionStorage.getItem('headerImg');
          }else{
            headerimg = this.imgSrc;
          }
        }else{
          headerimg = this.userInfo.avatar;
        }
        option = {
          broomid: this.roomInfo.id,
          sname: this.userInfo.whoami || this.userInfo.nick || nick,
          sid: this.userInfo.id,
          type: 0,
          platid:config.platid,
          param: {
            TXT: this.sendTxt.replace('@' + this.sendData.rname, ''),
            CITE_TXT: this.sendData.param.CITE_TXT,
            CITE_TXT_ID: this.sendData.param.CITE_TXT_ID,
            CITE_TXT_NAME: this.sendData.param.CITE_TXT_NAME
          },
          headerimg,
        }
        option = Object.assign({}, this.sendData, option)
      }
      console.log(option);
      this.socket.send(JSON.stringify(option))
      this.sendTxt = ''
      this.replyCall = {
        show: false,
        name: '',
        txt: ''
      }
      this.sendType = 0
    },
    // 关闭弹出层
    handleCloseDialog() {
      this.$store.commit('home/set_dialogobj', { dialogVisible: false, isCloseIcon: true })
    },
    handleCloseDialog4() {
      this.$store.commit('home/set_dialogobj4', { dialogVisible: false, isCloseIcon: true });
      let num = Number(this.tipTime.num);
      let data = {
        minute:this.tipTime.minute,
        num:num-1,
      };
      this.$store.commit('home/set_tipTime',data);
    },
    // 关闭弹出层
    handleCloseDialog3() {
      this.$store.commit('home/set_dialogobj3', { dialogVisible: false, isCloseIcon: true })
    },
    // 点击空表部分隐藏用户列表和房间列表
    handleGolbalEvent() {
      this.$store.commit('home/set_showroomlist', false)
      this.$store.commit('home/set_showuserlist', false)
    },
    // 公聊屏幕滚动和冻结
    handleAutoScroll(bool) {
      this.$store.commit('home/set_isatuoscroll', bool)
    },
    // 关闭注册成功之后的dialog
    closerDialog(){
      this.craigDialog.registerVisible = false;
    },
    // 点击选择器
    selectShow(attr){
      if (!this.userInfo.id && !this.roomInfo.id) {
        this.$message({
          type: 'error',
          message: '请先登录并进入房间'
        })
        return false
      }
      this.controlClass = attr;
      this.$store.commit('home/set_selectTree',attr);
    },
    // 跳转登录
    handleLogin(attr) {
      this.$store.commit('home/set_dialogobj', {
        dialogVisible: true,
        isCloseIcon: false,
        type: 1,
        width: '98%'
      }),
      this.$store.commit('home/set_dialogobj4', { dialogVisible: false, isCloseIcon: true })
      if(attr === 1){
        this.$store.commit('login/set_activeName', 'second');
      }else if(attr === 2){
        this.$store.commit('login/set_activeName', 'first');
      }else{
        this.$store.commit('login/set_activeName', 'second');
        this.$store.commit('home/set_dialogobj', {
          dialogVisible: true,
          isCloseIcon: true,
          type: 1,
          width: '98%'
        });
      }
    },
    // 跳转客服链接
    live800(){
      // this.liveBody = !this.liveBody;
      this.$store.commit('home/set_showRed',false);
      console.log('我的信息',this.userInfo);
      if(this.userInfo.utype == 0){
        this.handlePrivateChatEvent(1, {}, true, false);
      }else{
        this.handlePrivateChatEvent(1, {}, true, true);
      }
    },
    // 点击头像事件
    tag(q,m){
      this.control.target = q;
      this.control.realImg = m;
    },
    // 点击更换头像
    changeImg(){
      sessionStorage.setItem('headerImg',this.control.realImg);
      this.control.headerImg = this.control.realImg;
      this.control.showDiv = false;
    }
  }
}
</script>

<style lang="less">
.classRoomBox{
  position: relative;
  .wapTab{
    width: 100%;
    height: auto;
    position:fixed;
    top: 0;
  }
}
.home-page {
  background: #fff;
  padding: 0 0 0px 0;
  overflow-y: auto;
  margin-top: 45px;
  /**主内容区域**/
  .home-page-main {
    display: flex;
    // flex-flow: row nowrap;
    // justify-content: flex-start;
    // margin-top: 30px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
    .main-header {
      height: 45px;
      line-height: 45px;
    }
  }
  .el-card__body, .el-main{
    padding: 0 !important;
  }
  /**主内容区域左边**/
  .video-section {
    // flex: 1;
    // position: relative;
    width: 100%;
    .video-header {
      background: #fff;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 0px;
      box-sizing: border-box;
      // padding: 10px 0;
      .changeBox{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 130px;
        .room-change-btn {
          width: 70px;
          cursor: pointer;
        }
      }
    }
    .video-sec {
      width: 100%;
      height: 220px;
      cursor: pointer;
    }
  }
  /**播放器**/
  .video-main {
    height: 220px;
    // position: relative;
    border-right: 1px solid #eff3f5;
    background: #000000;
  }
  /**讲师介绍模块 */
  .teacherIntroduce{
    width:100%;
  }
  /**主内容区域右边**/
  .chat-container {
    // float: right;
    width: 100%;
    background: #ffffff;
    min-width: 300px;
    margin-bottom: 45px;
    .chat-header {
      background: #ffffff;
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      // border-bottom: 1px solid #c6c6c6;
      .chat-header-left {
        .user-list-btn,
        .user-list-up-btn {
          display: inline-block;
          width: 16px;
          height: auto;
          cursor: pointer;
          margin: 17px 0 0 20px;
        }
      }
      .chat-header-right {
        position: relative;
        flex: 1;
        text-align: center;
        font-size: 14px;
        background-color: #f7f8fa;
        .chat-header-line {
          position: absolute;
          bottom: -1px;
          left: 0;
          right: 0;
          margin: auto;
          width: 104px;
          height: 2px;
          background: #3498db;
        }
        span{
          display: inline-block;
          width: 50%;
          height: 100%;
          cursor: pointer;
        }
        .currentSpan{
          color: #ee2547;
          font-weight: 600;
        }
      }
      .chat-header-living {
        width: 49px;
        height: 49px;
        text-align: center;
        line-height: 49px;
        cursor: pointer;
        img {
          width: 30px;
          height: 30px;
          margin-top: 8px;
        }
      }
    }
  }
  /**房间列表**/
  .room-list {
    width: 90%;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    overflow: hidden;
    box-shadow: 0px 5px 20px #c3c6c8;
    z-index: 5;
    border-radius: 5px;
  }
  /**聊天区域**/
  .chat-main {
    position: relative;
    height: auto;
    border-left: 1px solid #eff3f5;
  }
  /**用户列表**/
  .user-list {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
  }
  /**聊天区域**/
  .chat-section {
    position: relative;
    height: 600px;
    // background-color: #f7f7f7;
  }
  /*弹窗**/
  .dialog-close {
    position: absolute;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    top: -48px;
    right: -55px;
    background: url("./assets/images/dialog-close-icon.png") no-repeat center
      center;
    background-size: contain;
    cursor: pointer;
  }

  /**房间列表和用户列表显示隐藏动画**/
  .fade-enter-active,
  .fade-leave-active {
    // transition: top 0.3s;
  }
  .fade-enter,
  .fade-leave-active {
    top: -1000px;
  }
  .video-js .vjs-big-play-button {
    position:absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom:0;
    margin: auto;
    height: 3em;
    line-height: 3em;
    border-radius: 1.5em;
  }
  .vjs_video_3-dimensions.vjs-fluid {
    padding-top: 700px;
  }
  .video-player,.vjs-custom-skin {
    height: 100%;
  }
  .video-js.vjs-fluid, .video-js.vjs-16-9, .video-js.vjs-4-3 {
    height: 100%;
  }
  .vjs-tech {
    max-height: 700px !important;
  }
  .el-dialog__header{
    padding: 0;
  }
  .el-dialog__body{
    padding: 0;
    padding-bottom: 15px;
  }
  .registerBox{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .registerSuccess{
      margin: 30px auto;
    }
    .registerCenter{
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      .registerCenterLeft{
        width: 40%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        p{
          height: auto;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          box-sizing: border-box;
          padding: 10px;
          background-color: #d0081d;
          margin-bottom: 5px;
          img{
            width: 115px;
          }
          span{
            color: #fff;
            margin-top: 10px;
          }
        }
        button{
          width: 130px;
          height: 30px;
          border: 1px solid #8f9091;
          background: none;
          border-radius: 12px;
          margin-top: 10px;
        }
      }
      .registerCenterRight{
        width: 60%;
        height: 300px;
        box-sizing: border-box;
        padding-right: 25px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        line-height: 24px;
        p{
          width: 240px;
          height: 80px;
          background-color: #f7f7f7;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          box-sizing: border-box;
          padding-left: 20px;
          margin: 10px 0;
          span{
            color: #000000;
            font-size: 17px;
            font-weight: 510;
          }
        }
      }
    }
    .registerButton{
      width: 85%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      p{
        width: 190px;
        height: 45px;
        border: 1px solid #ee2547;
        color: #ee2547;
        border-radius: 7px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
      p:nth-child(2){
        background-color: #ee2547;
        color: #fff;
      }
    }
  }
  .dialog-craigNew{
    .craigNewP{
      width: 100%;
      height: 80px;
      background-color: #2c2c2c;
      color: #ffffff;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-left: 30px;
      box-sizing: border-box;
      font-size: 20px;
    }
    div{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      padding: 15px 0 20px;
      h3{
        color: #000000;
        font-size: 20px;
        span{
          color: #ee2547;
        }
      }
      p{
        margin-bottom: 20px;
        font-size: 17px;
      }
      button{
        width: 80%;
        height: 55px;
        border: 1px solid #ee2547;
        background: none;
        color: #ee2547;
        border-radius: 5px;
        margin-top: 15px;
        font-size: 17px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        img{
          width: 50px;
          margin-right: 5px;
        }
      }
      button:nth-child(4){
        background-color: #ee2547;
        color: #fff;
      }
    }
  }
}
.wapBottom{
  width: 100%;
  position:fixed;
  bottom: 0;
  .chat-handler {
    width: 100%;
    height: 45px;
    box-sizing: border-box;
    padding: 0 15px;
    background-color: #f7f8fa;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    .scroll-control {
      height: 40px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      position: relative;
      img {
        width: 20px;
        height: auto;
        cursor: pointer;
        margin-right: 8px;
      }
      p{
        position: relative;
        span{
          position: absolute;
          top: 0;
          right: 3px;
          width: 4px;
          height: 4px;
          background-color: #ee2547;
          border-radius: 50%;
        }
      }
      ul{
        width: 80px;
        position: absolute;
        bottom: 35px;
        left: -15px;
        background-color: #fff;
        box-shadow: 1px 1px 10px #bfc0c4;
        border-radius: 3px;
        li{
          font-size: 12px;
          border-bottom: 1px solid #f1f1f1;
          box-sizing: border-box;
          padding: 5px 0 5px 10px;
          color: #666666;
          cursor: pointer;
        }
        li:last-child{
          border-bottom: none;
        }
        .liRed{
          color:#ee2547
        }
      }
    }
    .headerBox{
      margin-right: 10px;
      .showHeaderImg{
        width: 40px;
      }
      .positionBox{
        width: 90%;
        height: auto;
        position: absolute;
        bottom: -125%;
        left: 50%;
        transform: translate(-50%,-50%);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 3;
        background-color: #ffffff;
        box-sizing: border-box;
        padding: 10px 0 10px;
        border-radius: 5px;
        box-shadow: 1px 1px 10px rgb(179, 176, 176);
        .closer{
          width: 12px;
          height: 12px;
          position: absolute;
          top: 5px;
          right: 5px;
        }
        p{
          width: 100%;
          flex-wrap: wrap;
          display: flex;
          justify-content: center;
          img{
            width: 40px;
            margin: 8px;
            cursor: pointer;
          }
          .borderImg{
            border: 2px solid #ee2547;
            border-radius: 50%;
          }
        }
        span{
          width: 35%;
          height: 35px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #ee2547;
          border-radius: 3px;
          color: #ffffff;
          cursor: pointer;
          font-size: 12px;
        }
      }
    }
    .noLogin{
      margin-right: 5px;
      img{
        width: 40px;
      }
    }
    /**输入区域**/
    .send-section {
      width: 100%;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      .send-input {
        height: 40px;
        width: 100%;
        textarea {
          width: 100%;
          height: 40px;
          border: none;
          outline: none;
          resize: none;
          margin: 0;
          box-sizing: border-box;
          padding: 5px 10px 10px 10px;
          border: 1px solid #c3c6c8;
          border-radius: 6px;
        }
        .textareaTip{
          cursor: pointer;
          position: absolute;
          top: 60%;
          left: 50%;
          transform: translate(-50%,-75%);
          color: #a7a4a4;
          font-size: 13px;
        }
      }
      .send-btn {
        height: 40px;
        text-align: right;
        margin-left: -5px;
        button {
          width: 80px;
          height: 40px;
          font-size: 12px;
          color: #ffffff;
          background: #f34461;
          border: none;
          border-bottom-right-radius: 6px;
          border-top-right-radius: 6px;
        }
      }
    }
  }
  /**回复引用展示区域**/
  .reply-section {
    position:absolute;
    bottom: 110px;
    left: 0;
    width: 100%;
    font-size: 12px;
    padding: 10px 15px;
    background: #fbf5ee;
    span {
      color: #8c99a5
    }
    p {
      max-width: 410px;
      color: #8c99a5;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .class-reply-btn {
      position: absolute;
      top: 2px;
      right: 2px;
      width: 15px;
      height: 15px;
      background: #8c99a5 url('./assets/images/private-close-icon.png') no-repeat center center;
      background-size: 8px 8px;
      cursor: pointer;
      border-radius: 50%;
    }
  }
}
@media screen and (max-width: 500px) {
  .home-page{
    .chat-section{
      height: 250px;
    }
    /*wap关闭弹窗**/
    .dialog-close {
      position: absolute;
      width: 70px;
      height: 70px;
      border-radius: 50%;
      top: -5px;
      right: -15px;
      background: url("./assets/images/dialog-close-icon.png") no-repeat center
        center;
      background-size: contain;
      cursor: pointer;
    }
    .dialog-craigNew{
      .craigNewP{
        height: 60px;
        padding-left: 20px;
        box-sizing: border-box;
        font-size: 16px;
        font-weight: 600;
      }
      div{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        padding: 10px 0 15px;
        h3{
          color: #000000;
          font-size: 16px;
          span{
            color: #ee2547;
          }
        }
        p{
          margin-bottom: 10px;
          font-size: 15px;
        }
        button{
          width: 80%;
          height: 45px;
          border: 1px solid #ee2547;
          background: none;
          color: #ee2547;
          border-radius: 5px;
          margin-top: 15px;
          font-size: 15px;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          img{
            width: 40px;
            margin-right: 5px;
          }
        }
        button:nth-child(4){
          background-color: #ee2547;
          color: #fff;
        }
      }
    }
  }
}
</style>
